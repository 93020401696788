/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#blast-injury",
    "aria-label": "blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Blast Injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/presbycusis-hero.jpg",
    alt: "Leaves background hero",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-diagnosis-and-treatment-of-a-blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-diagnosis-and-treatment-of-a-blast-injury",
    "aria-label": "symptoms diagnosis and treatment of a blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms, diagnosis, and treatment of a blast injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Blast injury is a type of acoustic shock that affects not only your ears but your brain as well. This kind of trauma involves damage to the hair cells in the organs of Corti, ", React.createElement(_components.a, {
    href: "/resources/",
    className: "c-md-a"
  }, "which is in the inner ear"), ". Blast injuries are common among military personnel and veterans who were exposed to loud sounds during their time in active duty. When a sound-induced trauma occurs, the person affected feels an increased amount of pressure in the ear. The blast waves that enter the ear may lead to injury and can even result in a hearing loss. If ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/pressure/",
    className: "c-md-a"
  }, "the acoustic pressure"), " exceeds a certain threshold, the ear will not be able to adapt, which can consequently require further medical assistance. Typically, a blast injury is more likely if the sound wave lasts for a short amount of time (about 1-2 minutes)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following information contains more information about blast injury. You can learn what causes this kind of trauma, how the brain affected, and what the symptoms. You will also learn about the three classifications of a blast injury—primary, secondary, tertiary—and their distinctive characteristics. Additionally, this page explains how a physician diagnosis a blast injury and what treatments are available. Of course, if you need more information, or would like more resources on blast injuries, we are available for a free consultation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "three-classifications-of-a-blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#three-classifications-of-a-blast-injury",
    "aria-label": "three classifications of a blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Three classifications of a blast injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As mentioned before, medical professionals classify blast injuries ", React.createElement(_components.strong, null, "into three categories:"), " primary, secondary, and tertiary."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "A primary blast injury"), " is one where the blast waves transmitted by a loud explosion or other high decibel sound cause damage to an organ or tissue. The trauma is considered primary because the pressure created by the wave is sole cause of the injury."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "A secondary blast injury"), " is one that is caused by flying objects or debris caused by a nearby explosion. In others, injuries that secondary are not because of the actual blast waves penetrating the ear. Rather, during an explosion blast, the trauma one sustains is from external objects hitting some region of an individual’s ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Tertiary blast injuries"), " are those that take place when the sound produced by the blast wind causes a person’s body to be physically thrown in the air. Tertiary trauma is caused by high-energy explosions."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-a-blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-a-blast-injury",
    "aria-label": "symptoms of a blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of a blast injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After a blast injury, a patient typically feels as ", React.createElement(_components.strong, null, "if the injured ear is clogged"), ". Thus, their ability to hear through the affected ear is also reduced. In addition to these symptoms, other a blast injury patient may experience tinnitus (ringing in the ear), ", React.createElement(_components.a, {
    href: "/hearing-loss/hyperacusis/",
    className: "c-md-a"
  }, "hyperacusis (auditory hypersensitivity)"), ", ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "vertigo, and/or balance disturbances"), ". Blast injury usually occurs in only one ear, and in most cases, the symptoms will go away on their own in just a few days."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally, the ", React.createElement(_components.strong, null, "symptoms of a blast injury do not progress further"), ", worsening with time. In fact, the opposite is true: ", React.createElement(_components.strong, null, "time is the best medicine"), " for those who have gone through a traumatic situation with their hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/organ-of-corti-blast-injury.png",
    alt: "Organ of blast injury",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosing-a-blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosing-a-blast-injury",
    "aria-label": "diagnosing a blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosing a blast injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To diagnose a blast injury, an ENT doctor will perform a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), ". A ", React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural/",
    className: "c-md-a"
  }, "sensorineural hearing loss"), " can be detected by using the results of your test, which is called an audiogram. The data will show either a dip at 4,000 Hz or a drop in the curve with high frequency tones. The SISI test (Short Increment Sensitivity Index) is positive, indicating what hearing care specialists call “positive recruitment.” This means that the interval between the auditory threshold and the threshold of hearing discomfort is smaller than with normal hearing. The faster increase in loudness can be explained by the damage to the outer hair cells. When healthy, the outer hair cells modify the sound. They amplify quiet sounds and soften loud sounds so that it is possible to perceive a wider range of frequencies. This modification disappears in patients with damaged hair cells due to a blast injury."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Otoacoustic emissions cannot be measured in the damaged frequency range. These emissions are based on the function of the outer hair cells, and likewise disappear in patients with damaged hair cells in the corresponding frequency range. Due to the test’s associated exposure to noise, it is not performed until at least 1 week after the traumatic incident."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-for-a-blast-injury",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-for-a-blast-injury",
    "aria-label": "treatment for a blast injury permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment for a blast injury"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Initial treatment options (as with sudden deafness) are hemorheologic infusion therapy with HAES (hydroxyethyl starch) and Procaine. Thereafter, cortisone can be administered. If both these treatments fail, hyperbaric oxygen therapy is another alternative. In any event, early initiation of treatment is critical for a good prognosis. ", React.createElement(_components.strong, null, "The earlier treatment is started, the lower the risk of permanent damage."), " Surgery is only recommended if one or both inner ear windows are damaged, or if a perilymphatic fistula has developed. This involves an abnormal connection between the perilymphatic space (the sensory cells surrounding the hearing organs) and the middle ear. An eardrum opening (tympanotomy) is performed to sanitize and repair the damaged window or an existing fistula."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The prognosis for a blast injury is generally very good."), " In most cases, a patient will notice improvements within 6 weeks of the incident – often to complete recovery. Problems which continue after 6 weeks show a poor prognosis and often cannot be treated further."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "blast-injury-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#blast-injury-and-hearing-aids",
    "aria-label": "blast injury and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Blast injury and hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As a rule, a lasting blast injury can lead to ", React.createElement(_components.a, {
    href: "/hearing-loss/high-frequency/",
    className: "c-md-a"
  }, "hearing damage in the upper frequency range"), ". This can be troublesome to the affected person in louder surroundings. With a permanent blast injury, ", React.createElement(_components.strong, null, "a hearing aid usually helps the patient to perceive higher sounds again.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How hearing aids help with a blast injury…")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A continuous blast injury usually leads to hearing loss in the high frequency range, which can be remedied effectively with hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The higher tones that are hard to hear can be perceived again."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing aids significantly improve hearing after a blast injury, especially in setting where there is a lot of loud noise."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improved quality of life."), "\n"), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up to try hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
